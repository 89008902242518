import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  constructor(
    private toastController: ToastController,
    private translate: TranslateService,
    ) {
  }

  async presentToast(msg: string, color: string) {
    const toast = await this.toastController.create({
      message: msg,
      color: color,
      duration: 2000
    });
    toast.present();
  }

  public error(msg: string) {
    this.presentToast(msg, 'danger');
  }

  public success(msg: string) {
    this.presentToast(msg, 'success');
  }
  
  public errorTosterwithTrans(msg: string) {
    this.translate.get(msg).subscribe((value:string) => {
      this.presentToast(value, 'danger');
    });
  }

  public successTosterwithTrans(msg: string) {
    this.translate.get(msg).subscribe((value:string) => {
      this.presentToast(value, 'success');
    });  }

  public swal(icon,title){

      this.translate.get(title).subscribe((value:string) => {
        Swal.fire({
          icon: icon,
          title: value,
          showConfirmButton: false,
          timer: 1000,
          background: '#fff',
          grow: false,
          position: 'center',
          // padding: '80% 0'

        })
      });
    }
}
