// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //testing API
  // apiUrl: 'https://hellodc.tecsaportal.com/api/', 
  // commentApiUrl:'https://hellodc.tecsaportal.com/api/Comment/Store',
  // new testing api 
  //   apiUrl: 'https://hellowdc.tecsaportal.com/dcpanel/api/', 
  // commentApiUrl:'https://hellowdc.tecsaportal.com/dcpanel/api/Comment/Store',



  // production API
  apiUrl: 'https://www.hellowdc.com/dcpanel/api/',
  commentApiUrl: 'https://www.hellowdc.com/dcpanel/api/Comment/Store',

  newsApiUrl: 'https://newsapi.org/v2',
  newsApiKey: 'e134161deedc46d081cade70751641a0',


  // apiToken: '%t33dhjjyuZq3t6w9z$C&F)KaPdSgVkYp3s6vJ@NcRfUjXn2r5u7x!A%D*G',
  refreshTokenApi: "https://securetoken.googleapis.com/v1/token?key=",
  firebaseConfig: {
    // apiKey: "AIzaSyCV9Iv_mucdEt166C3JfgezcW_1S-fkSjI",
    // authDomain: "hellodc-c6453.firebaseapp.com",
    // projectId: "hellodc-c6453",
    // storageBucket: "hellodc-c6453.appspot.com",
    // messagingSenderId: "267306823075",
    // appId: "1:267306823075:web:dd8da32c25857ceae63e16",
    // measurementId: "G-MGTPLWWL66"

    apiKey: "AIzaSyDi9R-9gRrE6kMaz4LNwG2wFeJ9aO7Fyyc",
    authDomain: "hellow-dc.firebaseapp.com",
    projectId: "hellow-dc",
    storageBucket: "hellow-dc.appspot.com",
    messagingSenderId: "707790157199",
    appId: "1:707790157199:web:26e49518d064b52ea6c7fa",
    measurementId: "G-Y4MBY3Y5RP"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
