import { Injectable } from '@angular/core';
import { StsTokenManager } from '../models/sts-token-manager.model';
import { UserModel } from '../models/user.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private _userInfoKey: string = 'hello-dc-user-info';
  private _tokenKey: string = 'hello-dc-token';
  private _fcmtokenKey: string = 'hello-dc-device-token';
  private _authtype: string = 'hello-dc-auth';
  private _idTokenKey: string = 'hello-dc-id-token';
  private _stsTokenManager: string = 'hello-dc-sts-token-manager';
  private _appVersion: string= 'hello-dc-appVersion';

  startTimer$ = new Subject<void>();

  constructor() { }

  get UserInfo(): UserModel {
    return <UserModel>this.getData(this._userInfoKey);
  }

  set UserInfo(v: UserModel) {
    this.setData(this._userInfoKey, v);
  }

  get Token(): string {
    return <string>this.getData(this._tokenKey);
  }

  set Token(v: string) {
    this.setData(this._tokenKey, v);
  }

  get FCMToken(): string {
    return <string>this.getData(this._fcmtokenKey);
  }
 
  set FCMToken(v: string) {
    this.setData(this._fcmtokenKey, v);
  }

  get appVer():string{
    return <string>this.getData(this._appVersion);
  }
  set appVer(v:string){
    this.setData(this._appVersion,v);
  }

  get Auth(): string {
    return <string>this.getData(this._authtype);
  }

  set Auth(v: string) {
    this.setData(this._authtype, v);
  }
  
  get IdToken(): string {
    return <string>this.getData(this._idTokenKey);
  }

  set IdToken(v: string) {
    this.setData(this._idTokenKey, v);
  }

  get Lang(): string {
    return <string>this.getData("lan");
  }

  set Lang(v: string) {
    this.setData("lan", v);
  }

  get StsTokenManager(): StsTokenManager {
    return <StsTokenManager>this.getData(this._stsTokenManager);
  }

  set StsTokenManager(v: StsTokenManager) {
    this.setData(this._stsTokenManager, v);
  }

  public clearData() {
    localStorage.removeItem(this._userInfoKey);
    localStorage.removeItem(this._idTokenKey);
    localStorage.removeItem(this._tokenKey);
    localStorage.removeItem(this._authtype);
    localStorage.removeItem(this._stsTokenManager);
  }

  private setData(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  private getData(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }
}
