import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { OfflineGuard } from './guards/offline.guard';

const routes: Routes = [
  {
    path: '',
    // loadChildren: () => import('./signin/signin.module').then( m => m.SigninPageModule),//for web
    // loadChildren: () => import('./getting-started/getting-started.module').then( m => m.GettingStartedPageModule),//for app
    loadChildren: () => import('./landing-page/landing-page.module').then( m => m.LandingPagePageModule),//for app
    canActivate: [OfflineGuard]
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [OfflineGuard]
  },
  
  {
    path: 'signin',
    loadChildren: () => import('./signin/signin.module').then( m => m.SigninPageModule),
    canActivate: [OfflineGuard]
  },
  {
    path: 'get-otp',
    loadChildren: () => import('./get-otp/get-otp.module').then( m => m.GetOtpPageModule),
    canActivate: [OfflineGuard]
  },
  {
    path: 'user-register',
    loadChildren: () => import('./user-register/user-register.module').then( m => m.UserRegisterPageModule),
    canActivate: [OfflineGuard]
  },
  {
    path: 'admin-login',
    loadChildren: () => import('./admin-login/admin-login.module').then( m => m.AdminLoginPageModule),
    canActivate: [OfflineGuard]
  },
  {
    path: 'getting-started',
    loadChildren: () => import('./getting-started/getting-started.module').then( m => m.GettingStartedPageModule),
    canActivate: [OfflineGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/admin-dashboard/admin-dashboard.module').then( m => m.AdminDashboardPageModule),
    canActivate: [OfflineGuard]
  },
  {
    path: 'no-internet',
    loadChildren: () => import('./no-internet/no-internet.module').then( m => m.NoInternetPageModule)
  },
  {
    path: 'landing-page',
    loadChildren: () => import('./landing-page/landing-page.module').then( m => m.LandingPagePageModule)
  },
  {
    path: 'officer-register',
    loadChildren: () => import('./officer-register/officer-register.module').then( m => m.OfficerRegisterPageModule)
  },
  {
    path: 'block-reason',
    loadChildren: () => import('./block-reason/block-reason.module').then( m => m.BlockReasonPageModule)
  },  {
    path: 'mills',
    loadChildren: () => import('./pages/mills/mills.module').then( m => m.MillsPageModule)
  },


  // {
  //   path: 'home-officer',
  //   loadChildren: () => import('./pages/home-officer/home-officer.module').then( m => m.HomeOfficerPageModule)
  // },



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }) //for app
    
    // RouterModule.forRoot(routes, { useHash: true })//for web
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
