import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { NativeKeyboard } from '@awesome-cordova-plugins/native-keyboard/ngx';

// geolocation and native-geocoder
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from '../environments/environment';
import { NgOtpInputModule } from  'ng-otp-input';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { HttpInterceptorService } from './interceptor/http-interceptor.service';
import { OfflineGuard } from './guards/offline.guard';
import { AppVersion} from '@ionic-native/app-version/ngx'
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
// import { File } from '@awesome-cordova-plugins/file/ngx';
// import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { IonicSelectableModule } from 'ionic-selectable';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent,ForgetPasswordComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    CommonModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgOtpInputModule,
    NgxDocViewerModule,
    IonicSelectableModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule
  ],
  providers: [
    // Camera,
    Geolocation,
    NativeKeyboard,
    NativeGeocoder,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AngularFirestoreModule,
    InAppBrowser,
    OfflineGuard,
    HttpInterceptorService,
    PhotoViewer,
    // File,
    // FileTransfer,
    {
      provide: HTTP_INTERCEPTORS,
			useClass: HttpInterceptorService,
			multi: true
    },
    AppVersion,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
