import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  online: boolean;
  goOnlineSub$ = new Subject<void>();

  constructor(private router: Router) {
    this.online = window.navigator.onLine;

    fromEvent(window, 'online').subscribe(e => {
      this.online = true;
      this.goOnlineSub$.next();
    });

    fromEvent(window, 'offline').subscribe(e => {
      this.online = false;
    });
  }

  public set ActiveUrl(value: string) {
    if (value) {
      localStorage.setItem('amardcbd-offline-url', value);
    } else {
      localStorage.setItem('amardcbd-offline-url', '/');
    }
  }

  public get ActiveUrl(): string {
    return localStorage.getItem('amardcbd-offline-url');
  }
}
