import { SessionService } from 'src/app/services/session.service';

import { Plugins } from '@capacitor/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';

// const { NativeMarket} =  Plugins

interface  AppUpdate{
  current: string;
  enabled: boolean;
  Msg?: {
    title: string;
    msg: string;
    btn: string;
  };
  
  majorMsg?: {
    title: string;
    msg: string;
    btn: string;
  };
  minorMsg?: {
    title: string;
    msg: string;
    btn: string;
  };
  
}


@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  updateUrl = 'https://www.hellowdc.com/dcpanel/api/App/UpdateJson';
  maintainencUrl='https://www.hellowdc.com/dcpanel/api/App/MaintainJson'

  constructor(
    private http: HttpClient,
    private appVersion: AppVersion,
    private alertCtrl: AlertController,
    private iab: InAppBrowser,
    private platform :Platform,
    private sessionService: SessionService
  ) { }


  async checkForUpdate(){
    this.http.get(this.updateUrl).subscribe(async (info: AppUpdate)=>{

      console.log('apiResult:', info);
     
      if(!info.enabled){
        this.prsentAlert(
          info.Msg.title,
          info.Msg.msg,
        )
      }else{
        const versionNumber = await this.appVersion.getVersionNumber();
        const splitVersion= versionNumber.split('.');
        const serverVersion= info.current.split('.');
        this.sessionService.appVer=versionNumber;

        console.log('server:',serverVersion, 'split:',splitVersion);


        if(serverVersion[0]>splitVersion[0]){
          this.prsentAlert(info.majorMsg.title,info.majorMsg.msg,info.majorMsg.btn)
        }else if(serverVersion[1]>splitVersion[1]){
          this.prsentAlert(info.minorMsg.title,info.minorMsg.msg,info.minorMsg.btn,true)

        }


        
      }
    })
  }
  openAppstoreEntry(){
    console.log('openAppStore');
    this.iab.create('https://play.google.com/store/apps/details?id=com.hellowdc.tecsa','_system')
    // if(this.platform.is('android')){
    //   // NativeMarket.openStoreListing({
    //   //   appId:'com.hellowdc.tecsa'
    //   // });
    //   this.iab.create('https://play.google.com/store/apps/details?id=com.hellowdc.tecsa','_system')
    // }else{
    //   this.iab.create('https://play.google.com/store/apps/details?id=com.hellowdc.tecsa','_system')
    // }
    
  }
  async prsentAlert(header, message, buttonText='',allowClose=false){

    const buttons: any= [];
    if(buttonText != ''){
      buttons.push({
        text:buttonText,
        handler:()=>{
          this.openAppstoreEntry();
        }
        
      })
    }

    if(allowClose){
      buttons.push({
        text:'Close',
        role:'cancel'
      })
    }

    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons,
      backdropDismiss:allowClose
    });
    await alert.present();
  }




}
