import { ToastrService } from './../services/toastr.service';
import { ModalController } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-reason',
  templateUrl: './block-reason.page.html',
  styleUrls: ['./block-reason.page.scss'],
})
export class BlockReasonPage implements OnInit {

  isOthers:boolean=false
  othersForm:FormGroup
  reasonReport:string='';


  constructor(
    private modalCtrl: ModalController,
    private toaster: ToastrService
  ) { 
    this.othersForm= new FormGroup({
      otherReason: new FormControl(null, [Validators.required])
    })
    console.log(this.othersForm.value.otherReason);
    
  }

  close(){
    this.modalCtrl.dismiss();
  }

  setReport(val){    
    if(val == 'others'){
      this.isOthers = true;
      
    }
    else{
      this.isOthers = false;
      this.reasonReport = val;
    }

  }
  submit(){
    if (this.isOthers) {
      if (this.othersForm.value.otherReason == null) {
        this.toaster.errorTosterwithTrans('homeUser.pleaseFillUp');
      }else{
        this.reasonReport = this.othersForm.value.otherReason ;
        let payload = {
          reason: this.reasonReport
        }
        console.log(payload);
        this.modalCtrl.dismiss(this.reasonReport);
      }
    }else{
      let payload = {
        reason: this.reasonReport
      }
      console.log(payload);
      this.modalCtrl.dismiss(this.reasonReport);
    }
  }

  ngOnInit() {
  }

}
