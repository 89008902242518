import { UpdateService } from './services/update.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { fromEvent, Subscription } from 'rxjs';
import { NetworkService } from './no-internet/network.service';
import { FcmServiceService } from './services/fcm-service.service';
import { FirebaseAuthService } from './services/firebase-auth.service';
import { RestService } from './services/rest.service';
import { SessionService } from './services/session.service';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  private timer: any;
  goOnlineSubsription: Subscription;
  startTimerSubscription: Subscription;

  constructor(private router: Router,
    private platform: Platform,
    private fcmservice: FcmServiceService,
    private sessionService: SessionService,
    private restService: RestService,
    private authService: FirebaseAuthService,
    private networkService: NetworkService,
    private update: UpdateService
  ) {

    fromEvent(window, 'online').subscribe(e => {
      this.router.navigate([this.networkService.ActiveUrl]);
    });

  }

  ngOnInit(): void {

    this.platform.ready().then(() => {
      this.fcmservice.initPush();

      if (Capacitor.platform !== 'web') {
        this.update.checkForUpdate();
      }
      if (Capacitor.platform =='web') {
        this.sessionService.Lang = 'bn'; //for web
        
      }
    });


    if (this.sessionService.Lang) {
      if (this.sessionService.Token) {
        this.checkTokenExpiry();
        this.runTimer();
      } else {
        this.router.navigate(['/signin']);
      }
    } else {
      this.router.navigate(['/getting-started']);
    }

    this.goOnlineSubsription = this.networkService.goOnlineSub$.subscribe(() => {
      this.checkTokenExpiry();
    });

    this.startTimerSubscription = this.sessionService.startTimer$.subscribe(() => {
      this.runTimer();
    });
  }

  refreshExpiredToken() {
    if (this.networkService.online) {
      this.restService.refreshToken().subscribe((response: any) => {
        this.sessionService.Token = response.access_token;
        this.sessionService.IdToken = response.id_token;
        this.sessionService.StsTokenManager = {
          accessToken: response.access_token,
          expirationTime: (new Date()).getTime() + 3600000,
          refreshToken: response.refresh_token
        };
        this.runTimer();
      }, error => {
        this.authService.logout();
      });
    }
  }

  checkTokenExpiry() {
    const currentTimestamp = (new Date()).getTime();
    const tokenTimestamp = this.sessionService.StsTokenManager.expirationTime - 300000;
    if (currentTimestamp > tokenTimestamp) {
      this.refreshExpiredToken();
    }
  }

  runTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }

    this.timer = setInterval(() => {
      const currentTimestamp = (new Date()).getTime();
      const tokenTimestamp = this.sessionService.StsTokenManager.expirationTime - 300000;
      if (currentTimestamp > tokenTimestamp) {
        this.refreshExpiredToken();
      }
    }, 60000);
  }

  ngOnDestroy(): void {
    this.goOnlineSubsription.unsubscribe();
  }
}
