import { Injectable, NgZone } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/compat/app';
import { RestService } from './rest.service';
import { ToastrService } from './toastr.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {
  FirebaseResponse: firebase.default.auth.UserCredential;
  user: any;

  constructor(
    public afStore: AngularFirestore,
    public ngFireAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    private restService: RestService,
    private toastr: ToastrService,
    private sessionService: SessionService
  ) { }

  googleAuth() {
    return this.authLogin(new firebase.default.auth.GoogleAuthProvider());
  }

  facebookAuth() {
    return this.authLogin(new firebase.default.auth.FacebookAuthProvider());
  }

  phoneLogin(phoneNumber: string, appVerifier) {
    const phoneNumberString =  phoneNumber;
    return new Promise((resolve, reject) => {
      this.ngFireAuth.signInWithPhoneNumber(phoneNumberString, appVerifier)
        .then(confirmationResult => {
          resolve(confirmationResult);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  authLogin(provider) {
    return this.ngFireAuth.signInWithPopup(provider)
      .then((result) => {
        console.log(result);
        this.sessionService.Token = result.user.multiFactor['user'].accessToken;
        this.sessionService.StsTokenManager = result.user.multiFactor['user'].stsTokenManager;
        this.sessionService.startTimer$.next();
        this.storeDatainOurDB(result);
      }).catch((error) => {
        window.alert(error)
      })
  }

  logout() {
    this.ngFireAuth.signOut().then((result) => {
      this.sessionService.clearData();
      this.router.navigate(['/signin']);
    })
  }

  storeDatainOurDB(fireBaseResponce) {
    this.restService.userLogin(fireBaseResponce).subscribe((response: any) => {
      this.sessionService.UserInfo = response.data;
      this.sessionService.Auth = response.data.authType;
      this.toastr.success("Successfully Login");
      this.router.navigate(['/tabs/home']);
    }, error => {
      this.toastr.error(error);
    });
  }
}
