import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { SessionService } from '../services/session.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { RestService } from '../services/rest.service';
import { ToastrService } from '../services/toastr.service';
import { UserModel } from '../models/user.model';
import { BlockReasonPage } from '../block-reason/block-reason.page';


@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.scss'],
})
export class ViewProfileComponent implements OnInit {


  user: any;
  type: any;
  emailIs:string;
  tempPass: string;
  ok:string;
  title: string;
  alers_translate_Are_You_Sure: string;
  yes: string;
  no: string;
  loginUser: UserModel;

  constructor(
    private sessionService: SessionService,
    private modalCtrl: ModalController,
    private restService: RestService,
    private toastr: ToastrService,
    private navParams: NavParams,
    private translate: TranslateService,
    public loadingController: LoadingController,
  ) {
    this.translate.get('profilePage.emailIs').subscribe((res: string) => {
      this.emailIs = res;
    });
    this.translate.get('adminLogin.password').subscribe((res: string) => {
      this.tempPass = res;
    });
    this.translate.get('toastrService.ok').subscribe((res: string) => {
      this.ok = res;
    });
    this.translate.get('toastrService.passwordSend').subscribe((res: string) => {
      this.title = res;
    });
    this.translate.get('homeUser.Areyousure').subscribe((res: string) => {
      this.alers_translate_Are_You_Sure = res;
    });
    this.translate.get('homeUser.yes').subscribe((res: string) => {
      this.yes = res;
    });
    this.translate.get('homeUser.no').subscribe((res: string) => {
      this.no = res;
    });
   }

  ngOnInit() {
    this.type=this.sessionService.Auth;
    this.loginUser = this.sessionService.UserInfo;
    console.log(this.loginUser.role_name);
    console.log(this.user);
    
  }

  closeProfile(){
    this.modalCtrl.dismiss();
  }

  
  resetPass(user:any){
    console.log(user);
      Swal.fire({
        icon: 'question',
        title: this.alers_translate_Are_You_Sure,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: this.yes,
        cancelButtonText: this.no,
        background: '#fff',
        grow: false,
        position: 'center',
        // padding: '80% 0',
        
    
    
    
    
      }).then((result)=>{
        if(result.isConfirmed){
          this.resetPassFn(user.email);
        }
      })
  }
  resetPassFn(email:any){
    if(email){
    this.showLoader();
    let body = {
      "email": email,
    };
    console.log(body);

    this.restService.ResetPassword(body).subscribe((response: any) => {
      if(response){
    console.log(response);
    this.hideLoader();
    this.toastr.swal('success','toastrService.passwordSend');
    Swal.fire({
      icon: 'success',
      title: this.title,
      // text: this.emailIs+':'+this.adminFormGroup.value.email+' 'Temporary Password:'+response.oneTimePassword,
      html: '<p>'+this.emailIs+': '+email+' <br>'+this.tempPass+': <strong>'+response.oneTimePassword+'</strong></p>',
      showConfirmButton: true,
      confirmButtonText: this.ok,
      background: '#fff',
      grow: false,
      position: 'center',
    });


      }
    },error => {
      this.toastr.error(error);
      this.hideLoader();
    });
  }
  else{

  }
  }

  blockProfile(userId,reason){
    console.log(userId);
    let heading='';
    let body = '';
    let yes = '';
    let no = '';
    this.translate.get('homeUser.Areyousure').subscribe((res: string) => {
      heading = res;
    });
    this.translate.get('homeUser.blockUser').subscribe((res: string) => {
      body = res;
    });
    this.translate.get('homeUser.yes').subscribe((res: string) => {
      yes = res;
    });
    this.translate.get('homeUser.no').subscribe((res: string) => {
      no = res;
    });
    Swal.fire({
      icon: 'question',
      title: heading,
      text: body,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: yes,
      cancelButtonText: no,
      background: '#fff',
      grow: false,
      position: 'center',
      // padding: '80% 0',
      
  
  
  
  
    }).then((result)=>{
      if(result.isConfirmed){
       console.log(result);
        this.block(userId,reason);
       
      }
    })
    
  }
  block(userArray,reason){
    this.showLoader();
    let data = {
      "user_id": [userArray],
      "curr_user":this.sessionService.UserInfo.id,
      "reason": reason,
    }

    this.restService.post('User/Block',data).subscribe((response:any)=>{
      console.log(response);
      this.hideLoader();
      this.toastr.successTosterwithTrans('toastrService.userBlockedSuccessfully');
      this.closeProfile();
    },error =>{
        console.log(error);
        this.hideLoader();
      this.toastr.errorTosterwithTrans('toastrService.cantBlock');
    });
  }
  reportProfile(blockid,reason){
    let heading='';
    let body = '';
    let yes = '';
    let no = '';
    this.translate.get('homeUser.Areyousure').subscribe((res: string) => {
      heading = res;
    });
    this.translate.get('homeUser.reportUser').subscribe((res: string) => {
      body = res;
    });
    this.translate.get('homeUser.yes').subscribe((res: string) => {
      yes = res;
    });
    this.translate.get('homeUser.no').subscribe((res: string) => {
      no = res;
    });
    Swal.fire({
      icon: 'question',
      title: heading,
      text: body,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: yes,
      cancelButtonText: no,
      background: '#fff',
      grow: false,
      position: 'center',
      // padding: '80% 0',
      
  
  
  
  
    }).then((result)=>{
      if(result.isConfirmed){
       console.log(result);
        this.userBlockApi(blockid,reason);
       
      }
    })
  }
  userBlockApi(blockid,reason){
    this.showLoader();
    let payload= {
      "token_no": '',
      "user_id": blockid,
      "report_by": this.sessionService.UserInfo.id,
      "reason": reason,
    }
    this.restService.post('User/Report',payload).subscribe((response: any) => {
      if(response){
        console.log(response);
        this.toastr.successTosterwithTrans('toastrService.reportedUser');
        this.hideLoader();
        this.closeProfile();
      }
    },error => {
      console.log(error);
      this.hideLoader();
      this.toastr.errorTosterwithTrans('toastrService.cantReport');
    })
  }

  async reportReason(user_id,btnval){
    
    const reason = await this.modalCtrl.create({
      component: BlockReasonPage,
    });
    reason.onDidDismiss().then((modelData) => {
      console.log(modelData);
      if(modelData.data){
        if(btnval == 'report'){
          this.reportProfile(user_id,modelData.data);      
        }
        else{
          this.blockProfile(user_id,modelData.data);      
        }
      }

    });
    await reason.present();

  }
  showLoader() {
    this.loadingController
      .create({
        spinner: 'bubbles',
        message: 'Please wait...',
        backdropDismiss: false,
      })
      .then((res) => {
        res.present();
      });
  }

  hideLoader() {
    this.loadingController.dismiss().then((res) => {});
  }
}
