// import { PushNotifications } from '@capacitor/push-notifications';
import { PushNotification, PushNotificationActionPerformed,  PushNotificationToken } from '@capacitor/push-notifications';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {Plugins, Capacitor } from '@capacitor/core';
import { async } from '@firebase/util';
import { SessionService } from './session.service';

const {PushNotifications} = Plugins;

@Injectable({
  providedIn: 'root'
})
export class FcmServiceService {

  constructor(
    private router: Router,
    private sessionService: SessionService,

  ) { }

  initPush(){
    if(Capacitor.platform !== 'web'){
      this.registerPush();
    }
  }

  private registerPush(){
    PushNotifications.requestPermissions().then((permission) => {
      if(permission.granted){
        PushNotifications.register();
      }else{

      }
    });

    PushNotifications.addListener(
      'registration',
      (token: PushNotificationToken) =>{
        console.log('My Token:' + JSON.stringify(token));
        this.sessionService.FCMToken = token.value;
        
        
      }
    );



    PushNotifications.addListener('registrationError',(error:any)=>{
      console.log('error:'+ JSON.stringify(error));
      
    });

    if (this.sessionService.UserInfo) {
       PushNotifications.addListener('pushNotificationReceived', 
      async (notification: PushNotification)=>{
        console.log('pushRecived:'+ JSON.stringify(notification));
        
      }
    );




    PushNotifications.addListener('pushNotificationActionPerformed',
    async (notification: PushNotificationActionPerformed)=>{
      const data= notification.notification.data;
      console.log('ActionPerformed:'+ JSON.stringify(notification.notification.data));
      if(data.token){
        this.router.navigateByUrl('/tabs/home/'+data.token+'/'+data.event);
      }
    }
    
    );



    }
   


  }


  
getDeviceToken(){
  if(Capacitor.platform !== 'web'){
    PushNotifications.requestPermissions().then((permission) => {
      if(permission.granted){
        PushNotifications.register();
      }else{

      }
    });

    PushNotifications.addListener(
      'registration',
      (token: PushNotificationToken) =>{
        console.log('My Token:' + JSON.stringify(token));
        this.sessionService.FCMToken = token.value;              
      }
    );
  }
}
}
