import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(
    private httpClient: HttpClient,
    private sessionService: SessionService,
  ) {
  }

  get httpOptions() {
    return {
      headers: new HttpHeaders(
        {
          'token': this.sessionService.Token,
          'Content-Type': 'application/json',
          'authType': this.sessionService.Auth,
          'lang': this.sessionService.Lang,
        })
    };
  }
  get httpOptionsforusergmail() {
    return {
      headers: new HttpHeaders(
        {
          'token': this.sessionService.Token,
          'Content-Type': 'application/json',
        })
    };
  }
  get httpOptionsforAdminLogin() {
    return {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json'
        })
    };
  }

  public get(endpoint: string, params: any = {}, ignoreBaseUrl: boolean = false) {
    let headers = this.httpOptions;
    headers['params'] = params;
    if (ignoreBaseUrl) {
      return this.httpClient.get(endpoint, this.httpOptions);
    } else {
      return this.httpClient.get(environment.apiUrl + endpoint, this.httpOptions);
    }
  }

  public post(endpoint: string, body: any) {
    return this.httpClient.post(environment.apiUrl + endpoint, body, this.httpOptions);
  }

  public userLogin(body: any) {
    return this.httpClient.post(environment.apiUrl + "Login", body, this.httpOptionsforusergmail);
  }
  public adminLogin(body: any) {
    return this.httpClient.post(environment.apiUrl + "Admin/Login", body, this.httpOptionsforAdminLogin);
  }
  public forgetPass(body: any) {
    return this.httpClient.post(environment.apiUrl + "Forget/Password", body, this.httpOptionsforAdminLogin);
  }
  public ResetPassword(body: any) {
    return this.httpClient.post(environment.apiUrl + "Reset/Password", body, this.httpOptions);
  }
  public addOfficer(body: any) {
    return this.httpClient.post(environment.apiUrl + "Officer/Create/SendMail", body, this.httpOptions);
  }
  public postLike(body: any) {
    return this.httpClient.post(environment.apiUrl + 'FeedPost/Like', body, this.httpOptions);
  }
  public postComment(body: any) {
    return this.httpClient.post(environment.commentApiUrl, body, this.httpOptions);
  }
  public updateComment(body: any) {
    return this.httpClient.post(environment.apiUrl + 'Comment/Update', body, this.httpOptions);
  }
  public deleteComment(body: any) {
    return this.httpClient.post(environment.apiUrl + 'Comment/Delete', body, this.httpOptions);
  }
  public postCommentBroadcast(body: any) {
    return this.httpClient.post(environment.apiUrl + 'FeedComment/Store', body, this.httpOptions);
  }
  public getComment(token, user_id) {
    return this.httpClient.get(environment.apiUrl + "Post/GetPostByToken/" + token + "?user_id=" + user_id, this.httpOptions);
  }
  public getCommentofBroadcast(token, user_id) {
    return this.httpClient.get(environment.apiUrl + "FeedPost/GetByToken/" + token + "?user_id=" + user_id, this.httpOptions);
  }

  public SearchPostByToken(token, user_id) {
    return this.httpClient.get(environment.apiUrl + "Post/SearchPostByToken/" + token + "?user_id=" + user_id, this.httpOptions);
  }

  public getLog(token) {
    return this.httpClient.get(environment.apiUrl + "GetLogByToken/" + token, this.httpOptions);
  }
  public postLog(body: any) {
    return this.httpClient.post(environment.apiUrl + "Post/Log", body, this.httpOptions);
  }

  public getOfficers() {
    return this.httpClient.get(environment.apiUrl + "Officer/User/List", this.httpOptions);
  }

  public assingOfficers(url: string, body: any) {
    return this.httpClient.post(environment.apiUrl + url, body, this.httpOptions);
  }

  public getPriority() {
    return this.httpClient.get(environment.apiUrl + "Priority/GetAll", this.httpOptions);
  }

  public getRole() {
    return this.httpClient.get(environment.apiUrl + "Role/GetAll", this.httpOptions);
  }
  public getStatus() {
    return this.httpClient.get(environment.apiUrl + "Status/GetAll", this.httpOptions);
  }
  public getCountry() {
    return this.httpClient.get(environment.apiUrl + "Country/Code", this.httpOptionsforAdminLogin);
  }
  public changeStatus(body: any) {
    return this.httpClient.post(environment.apiUrl + "Status/Change", body, this.httpOptions);
  }

  public refreshToken() {
    return this.httpClient.post(`${environment.refreshTokenApi}${environment.firebaseConfig.apiKey}`, {
      grant_type: 'refresh_token',
      refresh_token: this.sessionService.StsTokenManager.refreshToken
    });
  }
}
