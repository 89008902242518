import { UserModel } from 'src/app/models/user.model';
import { RestService } from 'src/app/services/rest.service';

import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastrService } from '../services/toastr.service';
import { SessionService } from '../services/session.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { OfficerActionComponent } from '../officer-action/officer-action.component';
import { ViewProfileComponent } from '../view-profile/view-profile.component';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-officer-list',
  templateUrl: './officer-list.component.html',
  styleUrls: ['./officer-list.component.scss'],
})
export class OfficerListComponent implements OnInit {
  Btn: string = 'all';

  officerList: any;
  shortofficerList: any[] = [];
  loader: boolean = true;
  user:UserModel
  isSelectable: boolean=false;
  isPopoverOpen: boolean=false;
  list: any;
  dataGot:boolean=false;

  data = {
    Officer: 0,
  };
  can_change_officer_role: boolean;
  ok: string;
  text: string;
  




  constructor(
    private modalCtrl: ModalController,
    private restService: RestService,
    private toastr: ToastrService,
    private sessionService: SessionService,
    private translate: TranslateService,

  ) {
    this.user= this.sessionService.UserInfo;
    this.translate.get('toastrService.ok').subscribe((res: string) => {
      this.ok = res;
    });
    this.translate.get('toastrService.selectOfficer').subscribe((res: string) => {
      this.text = res;
    });
  }

  ngOnInit() {

    this.getOfficer('all');
  }




  selectList(data){
    if (data.checked == true) {
      let newArray = this.shortofficerList.filter(function (el) {
        return el.id !== data.id;
      });
      this.shortofficerList = newArray;
    } else {
      this.shortofficerList.push(data);
    }
  }
    async openPopover(officerListSelected: any){
      console.log(officerListSelected);
      
      if (officerListSelected.length==0) {
        Swal.fire({
          icon: 'warning',
          text: this.text,
          showConfirmButton: true,
          confirmButtonText: this.ok,
          background: '#fff',
          grow: false,
          position: 'center',
        });

      }else{
        const editComnt= await this.modalCtrl.create({
          component: OfficerActionComponent, 
          componentProps: {
            officerList: officerListSelected,
          },
          swipeToClose: true,
          initialBreakpoint: 0.5,
          breakpoints: [0,0.5,1]
        });
        editComnt.onDidDismiss().then((modelData) => {
          console.log(modelData.data);
          if (modelData.data) {
            this.getOfficer('all');
            // this.commentPost = this.post.comment
          }
        });
        await editComnt.present();
      }

      
      
      
    }



  closeList() {
    this.modalCtrl.dismiss();
  }

  officerActive(usrId, tab) {
    // console.log('officerActivated');
    let payLoad={
      "user_id":usrId,
      "tab": tab,
    }
    this.loader=true;

    this.restService.post("Officer/Activate", payLoad ).subscribe((response: any) => {
     
      this.toastr.swal('success','toastrService.officerActivated');
      this.officerList = response.data;

          console.log('deactive officer=>', this.officerList);

          this.loader = false;
      
    }, error => {
      
      this.toastr.error(error);
    })
  }

  // portChan(event: {
  //   component: IonicSelectableComponent,
  //   value: any
  // }) {
  //   console.log('port:', event.value);   
  //   this.data =  event.value

  // }

  getRoles(){
    this.restService.get("Roll/All").subscribe((response:any)=>{
      console.log(response.data);
      this.list= response.data;
      console.log('rolesName', this.list);
      
      this.dataGot=true;
      
      
    },
    (error) => {
      console.log(error);
    })
  }

  officerDeactive(usrId,tab) {
    // console.log('officerDeactivated');

    let payLoad={
      "user_id":usrId,
      "tab":tab
    }
    this.loader=true;

    this.restService.post("Officer/Deactivate", payLoad ).subscribe((response: any) => {
     
      this.toastr.swal('success','toastrService.officerDeactivated');
      // this.getOfficer('deactive');
      this.officerList = response.data;

          console.log('active officer=>', this.officerList);

          this.loader = false;
    }, error => {
      
      this.toastr.error(error);
    })
  }

  itemDrag(event,usrId, tab) {
    console.log(event);

    // console.log(event.detail.amount);

    if (event.detail.side == 'end') {
      // console.log('userActivated');
      this.officerActive(usrId, tab);
    } else if (event.detail.side == 'start') {
      // console.log('userDeactivated');
      this.officerDeactive(usrId, tab);
    }
  }

  allFilter() {
    this.Btn = 'all';
    this.shortofficerList= [];
    this.getOfficer('all');
  }
  activeFilter() {
    this.Btn = 'active';
    this.getOfficer('active');
  }
  deactiveFilter() {
    this.Btn = 'deactive';
    this.getOfficer('deactive');
  }

  getOfficer(filterVal) {

    if (filterVal == 'active') {
      this.loader=true;
      this.restService
        .get('Officer/List?filter=active')
        .subscribe((response: any) => {
          // console.log(response);
          this.officerList = response.data;

          console.log('active officer=>', this.officerList);

          this.loader = false;
        });
    } else if (filterVal == 'deactive') {
      this.loader=true;
      this.restService
        .get('Officer/List?filter=deactive')
        .subscribe((response: any) => {
          // console.log(response);
          this.officerList = response.data;

          console.log('deactive officer=>', this.officerList);

          this.loader = false;
        });
    } else {
      this.loader=true;
      this.restService.get('Officer/List').subscribe((response: any) => {
        // console.log(response);
        this.officerList = response.data;

        console.log('all officer=>', this.officerList);

        this.loader = false;
      });
    }
  }


  async viewProfile(user){
    const viewProfile= await this.modalCtrl.create({
      component: ViewProfileComponent, 
      componentProps: {
        user: user
      },
      swipeToClose: true
    });
    await viewProfile.present();
  }


}
