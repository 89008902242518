import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { RestService } from '../services/rest.service';
import { SessionService } from '../services/session.service';
import { ToastrService } from '../services/toastr.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { UserModel } from '../models/user.model';
import { OfficerListComponent } from '../officer-list/officer-list.component';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  adminFormGroup: FormGroup;
  addofficer: boolean;
  forgetPassword: boolean;
  Rolelist: any[]=[];
  emailIs:string;
  tempPass: string;
  ok:string;
  title: string;
  user: UserModel;
  limitlist: any[]=[];

  constructor(
    private modlaCtrl: ModalController,
    private restService: RestService,
    private toastr: ToastrService,
    private navParams: NavParams,
    private sessionService: SessionService,
    private translate: TranslateService,
    public loadingController: LoadingController,
  ) {
    if(this.sessionService.UserInfo){
      this.user= this.sessionService.UserInfo;
    }
    if(!this.sessionService.Lang){
      this.translate.setDefaultLang('bn');
      this.sessionService.Lang = 'bn';
      }
      else{
      this.translate.setDefaultLang(this.sessionService.Lang);
      }
      this.adminFormGroup = new FormGroup({
        email: new FormControl(),
        is_admin: new FormControl(false),
        can_add_assign_officer: new FormControl(false),
        is_internal_officer: new FormControl(false),
        officer_create_limit: new FormControl("15"),
      });

    this.addofficer = this.navParams.get('addofficer');
    this.forgetPassword = this.navParams.get('forgetPass');
    console.log(this.forgetPassword);
      this.getlimitList();

      this.translate.get('profilePage.emailIs').subscribe((res: string) => {
        this.emailIs = res;
      });
      this.translate.get('adminLogin.password').subscribe((res: string) => {
        this.tempPass = res;
      });
      this.translate.get('toastrService.ok').subscribe((res: string) => {
        this.ok = res;
      });
      this.translate.get('toastrService.passwordSend').subscribe((res: string) => {
        this.title = res;
      });

      
   }

  ngOnInit() {}

  closeForget(){
    this.modlaCtrl.dismiss()
  }
  chngvl(){
    if(this.adminFormGroup.value.is_admin){
      this.adminFormGroup.value.can_add_assign_officer = false
    }
    if(this.adminFormGroup.value.can_add_assign_officer){
      this.adminFormGroup.value.is_admin = false
    }
  }
  addOfficer(){
    if(this.adminFormGroup.value.email){
      let body = {
        "email": this.adminFormGroup.value.email,
        "is_admin": this.adminFormGroup.value.is_admin,
        "can_add_assign_officer": this.adminFormGroup.value.can_add_assign_officer,
        "is_internal_officer": this.adminFormGroup.value.is_internal_officer,
        "officer_create_limit": this.adminFormGroup.value.officer_create_limit,
        "user_id": this.sessionService.UserInfo.id,
      };
      console.log(body);
  
      this.restService.addOfficer(body).subscribe((response: any) => {
        if(response){
          console.log(response);
          if(this.sessionService.UserInfo.role_name == "Super Officer" && response.limit_reached){
                this.toastr.swal('error','toastrService.officerAddlimitReached');
          }
          else{
            if(response.userFound){
              this.toastr.swal('error','toastrService.userFound');
            }
            else{
              // this.toastr.swal('success','toastrService.passwordSend');
              Swal.fire({
                icon: 'success',
                title: this.title,
                // text: this.emailIs+':'+this.adminFormGroup.value.email+' 'Temporary Password:'+response.oneTimePassword,
                html: '<p>'+this.emailIs+': '+this.adminFormGroup.value.email+' <br>'+this.tempPass+': <strong>'+response.oneTimePassword+'</strong></p>',
                showConfirmButton: true,
                confirmButtonText: this.ok,
                background: '#fff',
                grow: false,
                position: 'center',
              });
            }
          }

          this.hideLoader();
          this.closeForget();
        }
      },error => {
        this.toastr.swal('error','toastrService.invalidEmail');

        this.hideLoader();
      });
    }
    else{
      this.toastr.swal('error','toastrService.invalidEmail');

    }
  }
  forgetPasswordfn(){
    console.log("response");

    if(this.adminFormGroup.value.email){
    let body = {
      "email": this.adminFormGroup.value.email,
    };
    this.showLoader();

    this.restService.forgetPass(body).subscribe((response: any) => {
      if(response){
        console.log(response);
        this.hideLoader();
        
        if(response.email_send){
          this.toastr.swal('success','toastrService.passwordSend');
          this.closeForget();
        }else{
          this.toastr.swal('error','toastrService.passwordNotSend');
        }
    
    


      }
      else{
        this.hideLoader();
      }
    },error => {
      this.toastr.error(error);
      this.hideLoader();
    });
  }
  else{
    this.toastr.swal('error','toastrService.invalidEmail');
    this.hideLoader();
  }
  }

  getlimitList() {
    this.restService.get('GetOfficer/CreateLimit').subscribe((response: any) => {
        console.log(response);
        this.limitlist = response.data.limit;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  async openOfficerList() {
    if (this.user.role_name=='Deputy Commissioner' || this.user.role_name=='Super Officer' ) {
      const officerList = await this.modlaCtrl.create({
        component: OfficerListComponent,
        
        swipeToClose: true,
      });
      return await officerList.present();
    }
    
  }



  showLoader() {
    this.loadingController
      .create({
        spinner: 'bubbles',
        message: 'Please wait...',
        backdropDismiss: false,
      })
      .then((res) => {
        res.present();
      });
  }

  hideLoader() {
    this.loadingController.dismiss().then((res) => {});
  }
}
