import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavParams } from '@ionic/angular';
import { UserModel } from '../models/user.model';
import { RestService } from '../services/rest.service';
import { SessionService } from '../services/session.service';
import { ToastrService } from '../services/toastr.service';
import { IonicSelectableComponent } from 'ionic-selectable';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-officer-action',
  templateUrl: './officer-action.component.html',
  styleUrls: ['./officer-action.component.scss'],
})
export class OfficerActionComponent implements OnInit {
  user: UserModel;
  list: any;
  dataGot:boolean=false;
  data = {
    Officer: {
      id : 0,
      name: ""
    },
  };
  officerListemail: any[]=[];
  officerListId: any[]=[];

  officers:any;
  alers_translate_Are_You_Sure: string;
  yes: string;
  no: string;
  officerLists: any;
  constructor(
    private modalCtrl: ModalController,
    private restService: RestService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private navParams: NavParams,
    private sessionService: SessionService,
    public loadingController: LoadingController,
    private alertCtrl: AlertController
  ) {
    if (!this.sessionService.Lang) {
      this.translate.setDefaultLang('bn');

      this.sessionService.Lang = 'bn';
    } else {
      this.translate.setDefaultLang(this.sessionService.Lang);
    }


    this.translate.get('homeUser.Areyousure').subscribe((res: string) => {
      this.alers_translate_Are_You_Sure = res;
    });
    this.translate.get('homeUser.yes').subscribe((res: string) => {
      this.yes = res;
    });
    this.translate.get('homeUser.no').subscribe((res: string) => {
      this.no = res;
    });





    if (this.navParams.get('officerList')) {
      this.officerLists = this.navParams.get('officerList');
      for(let obj of this.officerLists){
        this.officerListemail.push(obj.email);
        this.officerListId.push(obj.id);
      }
      console.log(this.officerListemail, this.officerListId,this.officerLists);

    }
    this.user= this.sessionService.UserInfo;

    console.log(this.user);
    
    this.getRoles();

  }
  getRoles(){
    this.restService.get("Roll/All").subscribe((response:any)=>{
      console.log(response.data);
      this.list= response.data;
      console.log('rolesName', this.list);
      this.dataGot=true;
    },
    (error) => {
      console.log(error);
    })
  }
  closeAction(val?){
    this.modalCtrl.dismiss(val)
  }

  resetpass(){
    Swal.fire({
      icon: 'question',
      title: this.alers_translate_Are_You_Sure,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: this.yes,
      cancelButtonText: this.no,
      background: '#fff',
      grow: false,
      position: 'center',
      // padding: '80% 0',
      
  
  
  
  
    }).then((result)=>{
      if(result.isConfirmed){
        this.resetConfirm();
      }
    })
  }

  resetConfirm(){
    this.showLoader();
    let payLoad = {
      "email": this.officerListemail,
    }
    console.log(payLoad);
    this.restService.post("Reset/Password",payLoad).subscribe((response:any)=>{
      console.log(response);
      this.hideLoader();
      this.toastr.swal('success','toastrService.passwordSend');
      this.closeAction(true);
      
    },
    (error) => {
      console.log(error);
      this.hideLoader();
    this.toastr.swal('error','toastrService.passwordNotSend');
    })

  }
  confirmRolechange(){
    this.showLoader();
    let payLoad = {
      "email": this.officerListemail,
      "role_name": this.data.Officer.name
    }
    console.log(payLoad);
    this.restService.post("Change/Role",payLoad).subscribe((response:any)=>{
      console.log(response);
      this.hideLoader();
      this.toastr.swal('success','toastrService.roleUpdated');
      this.closeAction(true);
    },
    (error) => {
      console.log(error);
      this.hideLoader();
    this.toastr.swal('error','toastrService.roleNotUpdated');
    })

    
  }

  showLoader() {
    this.loadingController
      .create({
        spinner: 'bubbles',
        message: 'Please wait...',
        backdropDismiss: false,
      })
      .then((res) => {
        res.present();
      });
  }

  hideLoader() {
    this.loadingController.dismiss().then((res) => { });
  }
  ngOnInit() {}


  handelMillPerm(event){
    console.log(event);

    this.showLoader();
let payLoad;
    if(event.detail.checked){
      payLoad= {
        "id": this.officerListId,
        "can_handel_mill":event.detail.checked
      }
    }else{
      payLoad= {
      "id": this.officerListId,
      "can_handel_mill":false
    }
    }
    
    console.log(payLoad);
    this.restService.post("MillEntry/OfficerRequestPermission",payLoad).subscribe((response:any)=>{
      console.log(response);
      this.hideLoader();
      this.toastr.swal('success','toastrService.roleUpdated');
      this.closeAction(true);
    },
    (error) => {
      console.log(error);
      this.hideLoader();
    this.toastr.swal('error','toastrService.roleNotUpdated');
    })
    
  }

}
